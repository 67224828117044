












































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import NewDecoComponent from '../NewDecoComponent.vue';
import NewFishComponent from '../NewFishComponent.vue';
import NewSkinComponent from '../NewSkinComponent.vue';
import ProductButtonComponent from '@/components/shop/ProductButtonComponent.vue';
import { Global } from '@/store/globalz';

@Component({
  components: {
    NewDecoComponent,
    NewFishComponent,
    NewSkinComponent,
    ProductButtonComponent,
  },
})
export default class NewsPost extends Vue {
  public get decoContestImgSrc() {
    return this.decoPeriod === 'submit'
      ? '/images/articles/DecoContestPoster.png?v=2'
      : this.decoPeriod === 'vote'
      ? '/images/articles/DecoContestVotePoster.png?v=1'
      : '/images/articles/DecoContestReleaseSoonPoster.png?v=1';
  }

  public get decoContestPage() {
    return this.decoPeriod === 'submit'
      ? '/decoContest'
      : this.decoPeriod === 'vote'
      ? '/decoContest/vote'
      : '/decoContest';
  }
  public get decoPeriod() {
    const ct = Date.now();
    return ct >= 1674921600000
      ? 'done'
      : ct >= 1674662400000
      ? 'vote'
      : 'submit';
  }

  public onCnyPurchase() {
    Global.cnyShopModal.show();
  }
}
